/* eslint-disable */
import dynamic from 'next/dynamic';


import GoogleTagManagerNoscript from '@magalu/mixer-modules/build/esm/novisual/GoogleTagManagerNoscript';
import HeaderDesktop from '@magalu/mixer-modules/build/esm/components/ecommerce/HeaderDesktop';
import PromoterHeader from '@magalu/mixer-modules/build/esm/components/ecommerce/PromoterHeader';
import SearchTitle from '@magalu/mixer-modules/build/esm/components/ecommerce/SearchTitle';
import Breadcrumb from '@magalu/mixer-modules/build/esm/components/ecommerce/Breadcrumb';
import SearchFilters from '@magalu/mixer-modules/build/esm/components/ecommerce/SearchFilters';
import Row from '@magalu/mixer-modules/build/esm/components/ecommerce/Row';
import Heading from '@magalu/mixer-modules/build/esm/components/ecommerce/Heading';
import SearchHeader from '@magalu/mixer-modules/build/esm/components/ecommerce/SearchHeader';
import ProductList from '@magalu/mixer-modules/build/esm/components/ecommerce/ProductList';
import Pagination from '@magalu/mixer-modules/build/esm/components/ecommerce/Pagination';
import TextList from '@magalu/mixer-modules/build/esm/components/ecommerce/TextList';
import TermList from '@magalu/mixer-modules/build/esm/components/ecommerce/TermList';
import PopularDepartments from '@magalu/mixer-modules/build/esm/components/ecommerce/PopularDepartments';
import FooterDesktopPMD from '@magalu/mixer-modules/build/esm/components/ecommerce/FooterDesktopPMD';
import Favicon from '@magalu/mixer-modules/build/esm/novisual/Favicon';
import Preconnect from '@magalu/mixer-modules/build/esm/novisual/Preconnect';
import GoogleTagManagerHead from '@magalu/mixer-modules/build/esm/novisual/GoogleTagManagerHead';
import Stewie from '@magalu/mixer-modules/build/esm/novisual/Stewie';
import AllIn from '@magalu/mixer-modules/build/esm/novisual/AllIn';
import Criteo from '@magalu/mixer-modules/build/esm/novisual/Criteo';
import Radware from '@magalu/mixer-modules/build/esm/novisual/Radware';

const pageModules = {
  GoogleTagManagerNoscript,
  HeaderDesktop,
  PromoterHeader,
  SearchTitle,
  Breadcrumb,
  SearchFilters,
  Row,
  Heading,
  SearchHeader,
  ProductList,
  Pagination,
  TextList,
  TermList,
  PopularDepartments,
  FooterDesktopPMD,
  Favicon,
  Preconnect,
  GoogleTagManagerHead,
  Stewie,
  AllIn,
  Criteo,
  Radware,
  ZipCodeInput: dynamic(() => import('@magalu/mixer-modules/build/esm/components/ecommerce/ZipCodeInput'),
    { ssr: false }
  ),
  SelectedFilters: dynamic(() => import('@magalu/mixer-modules/build/esm/components/ecommerce/SelectedFilters'),
    { ssr: false }
  ),
  SearchRecommendation: dynamic(() => import('@magalu/mixer-modules/build/esm/components/ecommerce/SearchRecommendation'),
    { ssr: false }
  ),
  Showcase: dynamic(() => import('@magalu/mixer-modules/build/esm/components/ecommerce/Showcase'),
    { ssr: false }
  ),
  ShortenWidget: dynamic(() => import('@magalu/mixer-modules/build/esm/components/ecommerce/ShortenWidget'),
    { ssr: false }
  ),
  Loader: dynamic(() => import('@magalu/mixer-modules/build/esm/components/ecommerce/Loader'),
    { ssr: false }
  ),
};

export default pageModules;
